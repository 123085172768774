<template>
  <div class="loan_item">
    <div class="inner inner1">
      <div class="content">
        <div class="left_cell">
          <h2>Are you in need of quick <br>and convenient cash? </h2>
          <p class="inner1_text">Look no further - KK Cash is here to help you. We understand that unexpected expenses can arise at any time, and sometimes you just need a little extra financial support.
          </p>
        </div>
        <div class="right_cell">
          <img src="~@/assets/images/loanImg/about_us.png">
        </div>
      </div>
    </div>
    <div class="inner inner2">
      <h1>How to get started?</h1>
      <div class="items">
        <div class="left_item">
          <img src="@/assets/images/loanImg/icon_people.png" alt="">
          <img class="right" src="@/assets/images/loanImg/icon_right.png" alt="">
        </div>
        <div class="right_item">
          <div class="item_info">
            <div class="info_tips">
              <div class="items">
                <!-- <img class="info_img1" src="@/assets/images/loanImg/app-icon-7.png" alt=""> -->
                <span>1</span>
                <div>
                  <!-- <h3>No salary slip needed</h3> -->
                  <p>Download the KK Cash app from the Google Play Store.</p>
                </div>
              </div>
              <div class="items">
                <!-- <img class="info_img1" src="@/assets/images/loanImg/app-icon-8.png" alt=""> -->
                <span>2</span>
                <div>
                  <!-- <h3>Instant approval</h3> -->
                  <p>Create your account by providing some basic information.</p>
                </div>
              </div>
              <div class="items">
                <!-- <img class="info_img1" src="@/assets/images/loanImg/app-icon-9.png" alt=""> -->
                <span>3</span>
                <div>
                  <!-- <h3>Double your limit in months</h3> -->
                  <p>Fill out the loan application form and submit it.</p>
                </div>
              </div>
              <div class="items">
                <!-- <img class="info_img1" src="@/assets/images/loanImg/app-icon-10.png" alt=""> -->
                <span>4</span>
                <div>
                  <!-- <h3>Double your limit in months</h3> -->
                  <p>Wait for our quick decision - usually within minutes.</p>
                </div>
              </div>
              <div class="items">
                <!-- <img class="info_img1" src="@/assets/images/loanImg/app-icon-11.png" alt=""> -->
                <span>5</span>
                <div>
                  <!-- <h3>Double your limit in months</h3> -->
                  <p>If approved, the funds will be transferred to your account in no time.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <div class="content left_item">
        <img class="ng" src="@/assets/images/loanImg/inner2_bg.png">
        <h1>Download the KK Cash app now and experience
          the convenience and reliability of borrowing
          money for your financial needs. We’re here to
          support you when you need it the most!</h1>
        <div class="down" @click="downloadApp()">
          <img src="@/assets/images/loanImg/Google-play.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=kk.cash.loan.credit')
    }
  }
}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    min-width: 1100px;
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 18px;
      color: #333;
    }
    .content{
      padding: 0px calc((100% - 1100px) / 2) 0;
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      .inner1_text{
        color: #333;
        line-height: 25px;
        margin: 40px 0;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #EE7E01;
      }
    }
  }
  .bottom-20{
    margin-bottom: 20px;
  }
  .inner1{
    padding: 0px 0;
    // background: url("~@/assets/images/loanImg/inner1_2.png") no-repeat;
        background: linear-gradient(#F19A47, #EF684B);
    // background-size: 100% 100%;
    color: white;
    .content{
      display: flex;
      padding: 0px calc((100% - 1250px) / 2) 0;
      .left_cell,.right_cell{
        flex: 1;
      }
      .left_cell{
        padding: 80px 50px 20px 50px;
        // background: linear-gradient(to bottom,#ED7E00 0% ,#ED7E00 5%, #A4C519 5% ,#A4C519 100%);
        h2{
          font-size: 40px;
          line-height: 55px;
        }
        p{
          color: white;
        }
      }
      .right_cell{
        flex: 1.3;
        img{
          margin: 25px auto !important;
          width: 400px;
          height: 340px;
          display: block;
        }
      }
    }
  }
  .inner2{
    // height: 420px;
    // background:#EEEEEE;
    text-align: center;
    padding:50px calc((100% - 1100px) / 2) 100px;
    h1{
      margin-bottom: 60px;
    }
    .items{
      display: flex;
    }
    .left_item{
      flex: 1;
      text-align: start;
      position: relative;
      img{
        display: block;
        // margin: 0 auto;
        width: 53%;
        margin-left: 70px;
      }
      .right{
        position: absolute;
        right: 40px;
        top: calc(50% - 95px);
        width: 100px;
        height: 120px;
        margin-left: 0;
      }
    }
    .right_item{
      flex: 1;
      margin-right:30px;
      text-align: left;
      line-height: 30px;
      padding-bottom: 70px;
      .item_info{
        // margin: 20px 0;
        padding: 30px 0;
        height: 100%;
        display: flex;
        .info_img1{
          width: 50px;
        }
        .info_tips{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .items{
            flex: 1;
            display: flex;
            background: #EF664B !important;
            box-shadow: 0 0 2px #999;
            box-sizing: border-box;
            padding: 15px 50px 15px 35px;
            border-radius: 5px;
            align-items: center;
            margin-bottom: 40px;
            img{
              width: 35px;
              margin-right: 10px;
            }
            span{
              // width: 33px;
              // height: 67px;
              font-size: 40px;
              font-family: Arial-Bold Italic, Arial;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 30px;
              -webkit-background-clip: text;
              font-style: oblique;
            }
            p{
              font-size: 12px;
              color: #fff;
              line-height: 20px;
              margin-left: 40px;
            }
          }
          .items:nth-last-child(1){
            margin-bottom: 0px !important;
          }
        }
      }
    }
  }
  .inner3{
    display: flex;
    flex-direction: column;
    height: 250px;
    background: #D6D6D6;
    .left_item{
      background-size: 250px 100%;
      background-position-x: 240px;
      position: relative;
      display: flex;
      align-items: center;
      h1{
        font-weight: bold;
        line-height: 28px;
        font-size: 18px;
        margin: 0 60px;
      }
      .down{
        img{
          display: block;
          width: 180px;
          margin: 0 auto;
        }
        p{
          font-weight: bold;
          font-size: 16px;
        }
      }
      .ng{
        width: 275px;
        // height: 100%;
      }
    }
  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    .inner{
      min-width: calc(100vw) !important;
    }
    .inner1{
      padding: 30px 0;
      background: white !important;
      color: #333;
      .content{
        padding: 0 20px !important;
        display: flex;
        flex-direction: column;
        .left_cell{
          padding: 20px 0;
          h2{
            padding: 20px;
            font-size: 25px;
            line-height: 35px;
          }
          p{
            color: #333;
          }
        }
        .cell_item{
          width: 100% !important;
          height: 100px;
        }
        .inner1_text{
          margin: 0;
          padding: 0 20px;
        }
        img{
          margin-top: 0px;
          width: 300px !important;
          height: 300px !important;
          margin: 0 auto ;
        }
      }
    }
    .inner2{
      padding: 20px calc((100% - 1100px) / 2) 0;
      width: calc(100vw);
      h1{
        line-height: 40px;
        margin-bottom: 0;
        font-size: 25px;
      }
      .items{
        flex-direction: column;
      }
      .left_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 260px;
          height: 480px;
          margin: 50px 0 0;
        }
        .right{
          display: none;
        }
      }
      .right_item{
        margin-right: 0;
        width: calc(100vw - 40px);
        margin: 0 auto;
        padding-bottom: 50px;
        p{
          font-size: 18px;
        }
        .items{
          padding: 20px !important;
          text-align: center;
          img{
            margin-bottom: 20px;
          }
        }
        // img{
        //   // width: 100%;
        //   // height: 60px;
        // }
        .item_tips{
          width: calc(100vw - 40px);
          p{
            font-size: 12px !important;
            width: 70px;
          }
        }
      }
    }
    .inner3{
      padding: 20px 20px;
      flex-direction: column;
      margin-bottom:0;
      .left_item{
        // background: #DAC8BD;
        position: relative;
        h1{
          width: 100%;
          font-size: 12px;
          margin: 80px 0 0 0 ;
          text-align: center;
          padding: 0 15px;
        }
        p{
          text-align: center;
          margin-bottom: 20px;
          margin-left: 0 !important;
        }
        div{
          position: absolute;
          top: 15px;
          left: calc(50% - 80px);
          img{
            width: 120px;
            height: 50px;
            margin: 0 auto 5px;
          }
        }
        .ng{
          display: none;
        }
      }
    }
  }
}
</style>
