<template>
  <div class="contact_us">
    <div class="con_nav">
      <nav-tab :nav-data="navData" />
    </div>
    <div class="con_center">
      <Loan />
    </div>
    <div class="con_bottom">
      <layout-bottom />
    </div>
  </div>
</template>

<script>
import NavTab from '@/components/NavTab'
import LayoutBottom from '@/components/LayoutBottom'
import Loan from '@/components/loan/loanItem'
export default {
  components: {
    NavTab,
    Loan,
    LayoutBottom
  },
  data() {
    return {
      navData: {
        index: 1,
        list: [
          {
            name: 'Home',
            link: '/'
          },
          {
            name: 'How to apply',
            link: '/how_to_apply'
          },
          {
            name: 'User experience',
            link: '/user_experience'
          }
        ]
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 768px){
  .con_center{
    // padding-top: 60px;
  }
}
@media only screen and (max-width: 767px){}

</style>
